<template>
  <div class="w-full mt-[23px] input-search-coordinator px-[20px] h-[92vh] pb-5">
    <UserList/>
  </div>
</template>

<script setup>
import { ref,computed } from 'vue';
import UserList from './components/user-list.vue';
const userName = ref('');
</script>
<style lang="scss">
.input-search-coordinator .ant-input-affix-wrapper > input.ant-input {
  background: #373737;
  color: #FFFFFF;
}
.input-search-coordinator .ant-input-affix-wrapper > input.ant-input::placeholder {
  color: #C4C4C4;
}
</style>
