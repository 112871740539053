<template>
  <div class="flex space-x-[16px]">
    <a-input
      v-model:value="userName"
      :placeholder="t('common.search')"
      class="bg-ems-tag1 border-none h-[38px] rounded-lg"
    >
      <template #prefix>
        <cds-feather-icons type="search" size="14" />
      </template>
    </a-input>
  </div>
  <div
    ref="scrollContainer"
    class="pr-[4px] hidden-scroll overflow-x-hidden"
    :style="{ height: `${windowHeight - 300}px` }"
    @scroll="handleScroll"
  >
    <div v-for="(entry, entryIndex) in listDialogs" :key="entryIndex">
      <div
        @click="handleClick(entry)"
        :class="`w-full h-[96px]  mt-[11px] ${
          idSelect === entry.otherInfo.peerId ||
          idHover === entry.otherInfo.peerId
            ? 'bg-ems-gray700'
            : ''
        } flex items-center px-[16px] space-x-[23px] rounded-lg relative cursor-pointer`"
        @mouseenter="idHover = entry.otherInfo.peerId"
        @mouseleave="idHover = ''"
      >
        <div
          :class="`rounded-full w-[64px] h-[64px] ${
            entry.dialogType === 1 ? 'bg-white ' : 'bg-ems-boTro2_500'
          } relative`"
        >
          <div
            class="rounded-full w-[64px] h-[64px] flex justify-center items-center overflow-hidden flex-shrink-0"
          >
            <img
              v-if="entry.dialogType === 1"
              alt="avatar"
              :src="entry.otherInfo.peerAvatarUrl"
              class="w-ful h-auto"
            />
            <div v-else>
              <p class="font-semibold text-3xl uppercase text-white">
                {{
                  entry.otherInfo.peerName && entry.otherInfo.peerName.charAt(0)
                }}
              </p>
            </div>
          </div>
          <div
            v-if="
              entry.otherInfo.userStatus &&
              entry.otherInfo.peerType === PEER_TYPE.USER &&
              entry.otherInfo.userStatus.online
            "
            class="h-[16px] w-[16px] border-2 rounded-full border-ems-tag1 bg-ems-boTro4_600 absolute bottom-0 right-0"
          ></div>
        </div>
        <div class="space-y-[8px] w-full">
          <p class="text-white font-semibold text-[16px]">
            {{ entry.otherInfo.peerName }}
          </p>
          <div
            v-if="entry.dialogType === 1"
            class="flex items-center justify-between w-full"
          >
            <p
              v-if="entry.lastMessage.senderId === idUser"
              :class="`${
                entry.countMessageUnread === 0
                  ? 'text-ems-gray300 font-normal'
                  : 'text-white font-bold'
              } font-normal text-[14px] truncate max-w-[200px] min-w-[150px] py-2`"
            >
              {{ t('coordinator.you') }}: {{ getContentLastMess(entry) }}
            </p>
            <p
              v-else
              :class="`font-normal text-[14px] truncate max-w-[250px] min-w-[150px] py-2 ${
                entry.countMessageUnread === 0
                  ? 'text-ems-gray300 font-normal'
                  : 'text-white font-bold'
              }`"
            >
              {{ getContentLastMess(entry) }}
            </p>
            <p
              :class="`${
                entry.countMessageUnread === 0
                  ? 'text-ems-gray300 font-normal'
                  : 'text-white font-bold'
              } font-normal text-[12px]`"
            >
              {{
                entry.lastMessage.timeUnix &&
                convertUnixToDate(entry.lastMessage.timeUnix, locale)
              }}
            </p>
          </div>
          <div v-else class="flex items-center justify-between w-full">
            <p
              v-if="entry.lastMessage.senderId === idUser"
              :class="`${
                entry.countMessageUnread === 0
                  ? 'text-ems-gray300 font-normal'
                  : 'text-white font-bold'
              } font-normal text-[14px] truncate max-w-[200px] min-w-[150px] py-2`"
            >
              {{ t('coordinator.you') }}: {{ getContentLastMess(entry) }}
            </p>
            <p
              v-else
              class="text-ems-boTro2_600 font-normal text-[14px] max-w-[200px] truncate min-w-[150px] py-2"
            >
              {{ entry.lastMessage.senderName }}:
              {{ getContentLastMess(entry) }}
            </p>
            <p
              :class="`${
                entry.countMessageUnread === 0
                  ? 'text-ems-gray300 font-normal'
                  : 'text-white font-bold'
              } font-normal text-[12px]`"
            >
              {{
                entry.lastMessage.timeUnix &&
                convertUnixToDate(entry.lastMessage.timeUnix, locale)
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { defineProps } from 'vue';
import VueTypes from 'vue-types';
import { formatTimeAudio } from '@/util/common-utils';
import _ from 'lodash';
import { debounce } from 'lodash';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { TAB_COORDINATION, TAB_WALKIE } from '@/util/common-constant.js';
import { convertUnixToDate } from '@/util/common-utils';
import { PEER_TYPE } from '@/util/common-constant.js';
import { useI18n } from 'vue-i18n';
import { useWindowHeight } from '@/util/common-utils';
const { state, dispatch } = useStore();
const { windowHeight } = useWindowHeight();
const { t } = useI18n();
const listDialogs = computed(() => state.coordinator.listDialogs);
const totalElements = computed(() => state.coordinator.totalElements);
const idUser = computed(() => state.auth.userInfo.id);
const router = useRouter();
const locale = ref(localStorage.getItem('locale'));
const route = useRoute();
const idSelect = ref('');
const idHover = ref('');
const DEBOUNCE_TIME = 500;
const scrollContainer = ref(null);
const page = ref(0);
const hoveredItem = ref('');
const initScroll = ref(true);
const userName = ref('');
const handleScroll = () => {
  if (scrollContainer.value) {
    const scrollTop = scrollContainer.value.scrollTop;
    const scrollHeight = scrollContainer.value.scrollHeight;
    const offsetHeight = scrollContainer.value.offsetHeight;
    if (scrollTop + offsetHeight >= scrollHeight - 1) {
      if (page.value <= Math.floor(totalElements.value / 10) + 1) {
        page.value++;
        fetchData(page.value, false, userName.value);
      }
    }
  }
};

const searchUser = debounce((query) => {
  fetchData(page.value, true, query);
}, DEBOUNCE_TIME);

watch(userName, (newVal) => {
  userName.value = newVal;
  searchUser(newVal);
});
watch(
  () => [route.query.id, route.query.tab_list],
  (newId, oldId) => {
    if (newId[1] === TAB_COORDINATION.COORDINATION) {
      const item = listDialogs.value.find(
        (item) => item.otherInfo.peerId === newId[0]
      );
      if (item) {
        handleClick(item);
      }
    } else {
      if (route.query && Object.keys(route.query).length === 0) {
        handleClick(listDialogs.value[0]);
      }
    }
  }
);

const fetchData = async (page, firstData, query) => {
  dispatch('coordinator/getListDialogs', {
    payload: { page: page, size: 10, q: query },
    firstData: firstData,
  }).then(() => {
    const item = listDialogs.value.find(
      (item) => item.otherInfo.peerId === route.query.id
    );
    if (initScroll.value) {
      if (item) {
        handleClick(item);
      } else {
        handleClick(listDialogs.value[0]);
      }
      initScroll.value = false;
    }
  });
};
onMounted(async () => {
  await fetchData(0, true, userName.value);
});
const handleSendParams = (item) => {
  let tab_walkie;
  if (
    route.query.tab_walkie === TAB_WALKIE.CHAT ||
    route.query.tab_walkie === TAB_WALKIE.WALKIE
  ) {
    tab_walkie = route.query.tab_walkie;
  } else {
    tab_walkie = TAB_WALKIE.WALKIE;
  }
  let query = {
    id: item.otherInfo.peerId,
    type: item.otherInfo.peerType,
    tab_list: TAB_COORDINATION.COORDINATION,
    tab_walkie: tab_walkie,
  };
  dispatch('coordinator/handleSetParams', query);
  dispatch('coordinator/viewMember', tab_walkie);
};
const handleReadMessage = (item) => {
  if (item.countMessageUnread > 0) {
    let value = {
      dialogId: item.dialogId,
      dialogType: item.dialogType,
      messageIdStr: item.lastMessage.messageIdStr,
    };
    dispatch('coordinator/readMessages', value);
  }
};
const handleGetMembers = async (item) => {
  let participants = await dispatch(
    'coordinator/getParticipants',
    item.otherInfo.peerId
  );
  if (item.otherInfo.peerType === PEER_TYPE.CHANNEL) {
    if (participants) {
      let ids = [];
      for (let key in participants) {
        ids.push(participants[key].userId);
      }
      await dispatch('coordinator/getListStatus', ids.join(','));
    }
  } else {
    await dispatch('coordinator/getListStatus', item.otherInfo.peerId);
  }
};
const getHistoryMess = (item) => {
  dispatch('coordinator/getMessagesByDialogs', {
    dialogId: item.dialogId,
    dialogType: item.dialogType,
    isChated: true,
  });
};
const handleSendItemValue = (item) => {
  let value = {
    dialogId: item.dialogId,
    dialogType: item.dialogType,
    otherInfo: {
      peerAvatarUrl: item.otherInfo.peerAvatarUrl,
      peerId: item.otherInfo.peerId,
      peerName: item.otherInfo.peerName,
      peerType: item.otherInfo.peerType,
      status:
        item.otherInfo.peerType === PEER_TYPE.USER &&
        item.otherInfo.userStatus.online,
    },
    tab: TAB_COORDINATION.COORDINATION,
  };
  dispatch('coordinator/selectValue', value);
};

const handleClick = async (item) => {
  handleSendParams(item);
  handleReadMessage(item);
  handleGetMembers(item);
  getHistoryMess(item);
  handleSendItemValue(item);
  idSelect.value = item.otherInfo.peerId;
};
const getContentLastMess = (mess) => {
  if (mess.lastMessage) {
    const { mediaType, length, content } = mess.lastMessage;
    if (mediaType && mediaType.includes('audio')) {
      return formatTimeAudio(length);
    } else if (mediaType && mediaType.includes('image')) {
      return t('coordinator.image');
    } else if (mediaType && mediaType.includes('video')) {
      return t('coordinator.video');
    } else {
      return content;
    }
  }
  return '';
};
</script>
<style>

</style>
